// NotFoundPage.jsx
'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import SearchAll from '../container/SearchAll.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import {
  headerHeight,
  iPhoneXSeriesFooterPaddingInPWA,
  color,
  textColor,
} from '../style/variables.js';
import ResetHeadingStyle from '../style/ResetHeadingStyle.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import TextEllipsis from '../style/TextEllipsis.js';
import CryfaceImageSource from '../../img/img_empty_nofollower.svg';

class NotFoundPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { staticContext } = this.props;

    if (staticContext) {
      staticContext.status = 404;
    }
  }

  handleClickEnter = () => {
    const { mergeMeData } = this.props;
    mergeMeData({ field: 'hasAgeVerified', value: true });
  };
  render() {
    const { isAuthed, hasAgeVerified, underAgeExitUrl, t, brandname } =
      this.props;

    return (
      <StyledNotFoundPage>
        <Header>
          <Logo as={Link} to="/" replace>
            <img src="/logo/hamburger" alt={brandname} />
          </Logo>
        </Header>
        <Body>
          <InformationSection>
            <CryFaceIcon src={CryfaceImageSource} aria-hidden />
            <Description>
              {t('page_not_found', {
                ns: TranslationNamespace.GENERAL,
              })}
            </Description>
          </InformationSection>
          {isAuthed || hasAgeVerified ? (
            <>
              <ActionSection>
                <Button as={Link} to="/" replace>
                  <ButtonText>
                    {t('back_to_home', {
                      ns: TranslationNamespace.GENERAL,
                    })}
                  </ButtonText>
                </Button>
              </ActionSection>
              <SearchAllSection>
                <SearchAll isActive />
              </SearchAllSection>
            </>
          ) : (
            <AgeVerifierSection>
              <WarningLabel>
                {t('warning', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </WarningLabel>
              <WarningTitle>
                {t('age_verify_title', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </WarningTitle>
              <WarningDescription>
                {t('age_verify_description', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </WarningDescription>
              <Button onClick={this.handleClickEnter}>
                <ButtonText>
                  {t('agree_terms_of_service_button_highlight', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ButtonText>
              </Button>
              <Exit
                href={underAgeExitUrl}
                rel="noreferrer noopener"
                title="exit"
              >
                {t('exit', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </Exit>
            </AgeVerifierSection>
          )}
        </Body>
      </StyledNotFoundPage>
    );
  }
}

NotFoundPage.propTypes = {
  isAuthed: PropTypes.bool,
  hasAgeVerified: PropTypes.bool,
  mergeMeData: PropTypes.func,
  underAgeExitUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  brandname: PropTypes.string,
  staticContext: PropTypes.object,
};

NotFoundPage.defaultProps = {
  mergeMeData: () => null,
  underAgeExitUrl: '',
  isAuthed: false,
  hasAgeVerified: false,
  brandname: 'SWAG',
  staticContext: null,
};

const StyledNotFoundPage = styled.div`
  position: relative;
  padding-top: ${headerHeight}px;
  padding-bottom: env(
    safe-area-inset-bottom,
    ${iPhoneXSeriesFooterPaddingInPWA}px
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: ${textColor.white};
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 0px 16px;
  width: 100%;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${color.black};
`;

const Logo = styled.div`
  > img {
    width: auto;
    height: 32px;
    object-fit: contain;
  }
`;

const Body = styled.div`
  width: 100%;
  flex: auto;
`;

const InformationSection = styled.div`
  margin-top: 40px;
  padding-left: 78px;
  padding-right: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CryFaceIcon = styled.img`
  margin-bottom: 12px;
  width: 120px;
  height: 120px;
`;

const Description = styled.h4`
  ${ResetHeadingStyle};
  color: ${textColor.black};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
`;

const ActionSection = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  ${ResetButtonStyle};
  border: 1px solid ${color.tealBlue};
  border-radius: 4px;
  padding: 0px 12px;
  height: 28px;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  ${TextEllipsis};
  color: ${textColor.primary};
  font-size: 14px;
  line-height: 1;
`;

const SearchAllSection = styled.div`
  margin-top: 40px;
`;

const AgeVerifierSection = styled.div`
  margin-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const WarningLabel = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.red};
  color: ${textColor.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;

const WarningTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: ${textColor.white};
  text-align: center;
`;

const WarningDescription = styled.div`
  margin-bottom: 82px;
  font-size: 14px;
  line-height: 1.5;
  color: ${textColor.white};
  text-align: center;
  white-space: pre-wrap;
`;

const Exit = styled.a`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${textColor.black};
`;

export default withTranslation()(NotFoundPage);
