// SearchAll.js
import { connect } from 'react-redux';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import fetchMessageSearch from '../action/fetchMessageSearch.js';
import fetchFeeds from '../action/fetchFeeds.js';
import fetchUserSearch from '../action/fetchUserSearch.js';
import fetchHashtagSearch from '../action/fetchHashtagSearch.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import SearchAll from '../component/SearchAll.jsx';
import withSearchQuery from '../component/WithSearchQuery.jsx';

import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';

import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

const mapStateToProps = (state, { query }) => {
  const hasQuery = !!query;
  const defaultFlixCategory = getOperationData(
    state,
    ['recommendFlixCategory'],
    'search'
  );
  const defaultStoryCategory = getOperationData(
    state,
    ['recommendStoryCategory'],
    'search'
  );
  const defaultSwaggerCategory = getOperationData(
    state,
    ['swaggerOnlineFeed'],
    'search'
  );

  const flixSelectPath = ['search', 'message', 'flix', query];
  const defaultFlixSelectPath = ['home', 'messages', defaultFlixCategory];
  const swaggerSelectPath = ['search', 'user', query];
  const defaultSwaggerSelectPath = ['home', 'users', defaultSwaggerCategory];
  const storySelectPath = ['search', 'message', 'story', query];
  const defaultStorySelectPath = ['home', 'messages', defaultStoryCategory];

  const isAuthed = !!getMeData(state, 'id');

  const searchFlixIds = getListData(state, flixSelectPath, 'itemIds');
  const defaultFlixIds = getListData(state, defaultFlixSelectPath, 'itemIds');
  const searchSwaggerIds = getListData(state, swaggerSelectPath, 'itemIds');
  const defaultSwaggerIds = getListData(
    state,
    defaultSwaggerSelectPath,
    'itemIds'
  );
  const searchStoryIds = getListData(state, storySelectPath, 'itemIds');
  const defaultStoryIds = getListData(state, defaultStorySelectPath, 'itemIds');

  const isSearchFlixFetched = getNetworkingData(
    state,
    [...flixSelectPath, 'firstPage'],
    'isFetched'
  );
  const isDefaultFlixFetched = getNetworkingData(
    state,
    [...defaultFlixSelectPath, 1],
    'isFetched'
  );
  const isSearchSwaggerFetched = getNetworkingData(
    state,
    [...swaggerSelectPath, 'firstPage'],
    'isFetched'
  );
  const isDefaultSwaggerFetched = getNetworkingData(
    state,
    [...defaultSwaggerSelectPath, 1],
    'isFetched'
  );
  const isSearchStoryFetched = getNetworkingData(
    state,
    [...storySelectPath, 'firstPage'],
    'isFetched'
  );
  const hasSearchStoryNextPage = !!getListData(
    state,
    storySelectPath,
    'nextPage'
  );
  const isDefaultStoryFetched = getNetworkingData(
    state,
    [...defaultStorySelectPath, 1],
    'isFetched'
  );
  const hasDefaultStoryNextPage = !!getListData(
    state,
    defaultStorySelectPath,
    'nextPage'
  );

  const deviceWidth = getOperationData(state, ['device'], 'width');
  const storyRenderAmount = getOperationData(
    state,
    ['search'],
    'storyRenderAmount'
  );

  const isRemoteConfigMerged = isAuthed
    ? getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_USER)
    : getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_CLIENT) ||
      getIsRemoteConfigMerged(state, CONFIG_PRIORITY_CONFIGURE);

  return {
    query,
    defaultFlixCategory,
    defaultSwaggerCategory,
    defaultStoryCategory,
    storyRenderAmount,
    deviceWidth,
    isAuthed,
    isFlixFetched: hasQuery ? isSearchFlixFetched : isDefaultFlixFetched,
    isSwaggerFetched: hasQuery
      ? isSearchSwaggerFetched
      : isDefaultSwaggerFetched,
    isStoryFetched: hasQuery ? isSearchStoryFetched : isDefaultStoryFetched,
    hasStoryNextPage: hasQuery
      ? hasSearchStoryNextPage
      : hasDefaultStoryNextPage,
    hasQuery,
    flixIds: hasQuery ? searchFlixIds : defaultFlixIds,
    swaggerIds: hasQuery ? searchSwaggerIds : defaultSwaggerIds,
    storyIds: hasQuery ? searchStoryIds : defaultStoryIds,
    isRemoteConfigMerged,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    fetchMessageSearch: data => dispatch(fetchMessageSearch(data)),
    fetchUserSearch: data =>
      dispatch(
        fetchUserSearch({
          ...data,
          selectPath: ['search', 'user', data.query],
        })
      ),
    fetchHashtagSearch: ({ query }) => dispatch(fetchHashtagSearch({ query })),
    setStoryRenderAmount: ({ amount }) =>
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['search'],
          data: {
            storyRenderAmount: amount,
          },
        },
      }),
  };
};

export default withSearchQuery(
  connect(mapStateToProps, mapDispatchToProps)(SearchAll)
);
