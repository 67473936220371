// NotFoundPage.js
'use client';
import { connect } from 'react-redux';

import NotFoundPage from '../page/NotFoundPage.jsx';
import getMeData from '../selector/getMeData.js';
import mergeMeData from '../action/mergeMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { UNDER_AGE_EXIT_URL, BRANDING_NAME } from '../RemoteConfigKeys.js';
import withRouter from '../component/WithRouter.jsx';

const mapStateToProps = state => {
  const meId = getMeData(state, 'id');
  const isAuthed = !!meId;
  const hasAgeVerified = getMeData(state, 'hasAgeVerified');
  return {
    isAuthed,
    hasAgeVerified,
    underAgeExitUrl: getRemoteConfigData(state, UNDER_AGE_EXIT_URL),
    brandname: getRemoteConfigData(state, BRANDING_NAME),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mergeMeData: ({ field, value }) => dispatch(mergeMeData({ field, value })),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotFoundPage)
);
